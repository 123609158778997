import React, { useState, useRef, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useGetOffersQuery } from '../../store/api';
import CustomModal from '../../components/Modals';
import ComplaintCard from '../../components/ComplaintCard';
import { Button, Alert, Row, Col, Tour } from 'antd';
import ExportExcelModal from '../../components/ExportExcelModal';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';

const Offer = () => {
	const { t } = useTranslation();
	const { data: offer, isLoading, refetch } = useGetOffersQuery();
	const [modalData, setModalData] = useState();
	const [openExportExcel, setOpenExportExcel] = useState(false);
	const [openTour, setOpenTour] = useState(false);

	// Refs for tour targets
	const titleRef = useRef();
	const exportRef = useRef();
	const cardRef = useRef();

	useEffect(() => {
		// Show tour only on first visit to offers page
		const hasSeenOfferTour = localStorage.getItem('hasSeenOfferTour');
		if (!hasSeenOfferTour) {
			setOpenTour(true);
			localStorage.setItem('hasSeenOfferTour', 'true');
		}
	}, []);

	const steps = [
		{
			title: t('tour.offer_page.title'),
			description: t('tour.offer_page.description'),
			target: () => titleRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.offer_export.title'),
			description: t('tour.offer_export.description'),
			target: () => exportRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.offer_card.title'),
			description: t('tour.offer_card.description'),
			target: () => cardRef.current,
			nextButtonProps: {
				style: { background: '#52c41a', borderColor: '#52c41a', color: 'white' },
				children: t('tour.finish'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
	];

	return (
		<>
			<Col xs={22} sm={22} xl={22}>
				<div ref={titleRef}>
					<PageTitle title={t('offer.title')}>
						<div>
							<Button ref={exportRef} onClick={() => setOpenExportExcel(true)}>
								{t('export_excel')}
							</Button>
						</div>
					</PageTitle>
				</div>
			</Col>

			{offer?.message && (
				<Col xs={2} sm={4} md={6} lg={8} xl={16}>
					<Alert type="info" showIcon message={t('offer.there_is_not_offers')} />
				</Col>
			)}
			{isLoading && (
				<Col xs={2} sm={4} md={6} lg={8} xl={16}>
					<Spinner animation="border" role="status">
						<span className="visually-hidden">{t('offer.loading')}</span>
					</Spinner>
				</Col>
			)}

			<Col xs={22} xl={22}>
				<Row justify="start" gutter={[16, 16]}>
					{offer?.offer?.map((item, index) => {
						return (
							<Col
								style={{ marginBottom: 20 }}
								key={item.id}
								xs={24}
								sm={12}
								md={12}
								lg={8}
								xl={{ span: 6, offset: index % 3 ? 1 : 0 }}
							>
								<div ref={index === 0 ? cardRef : null}>
									<ComplaintCard
										item={item}
										onClick={() => setModalData({ ...item, action: true })}
									/>
								</div>
							</Col>
						);
					})}
				</Row>
			</Col>
			<CustomModal
				data={modalData}
				isShow={modalData !== undefined}
				handleClose={() => setModalData(undefined)}
				updateList={refetch}
			/>
			<ExportExcelModal
				open={openExportExcel}
				onClose={() => setOpenExportExcel(false)}
				complaintStatus={0}
				choiseType="offer"
			/>
			<Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
		</>
	);
};

export default Offer;
