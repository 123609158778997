import React, { useState, useRef, useEffect } from 'react';
import { useGetComplaintQuery, useGetOffersQuery } from '../../store/api';
import { Button, Row, Col, Card, Tour } from 'antd';
import ExportExcelModal from '../../components/ExportExcelModal';
import CustomModal from '../../components/Modals';
import ComplaintCard from '../../components/ComplaintCard';
import PageTitle from '../../components/PageTitle';
import selectCategory from '../../utils/Category';
import { useTranslation } from 'react-i18next';

const ReportPage = () => {
	const { t } = useTranslation();
	const [openExportExcel, setOpenExportExcel] = useState(false);
	const { data: complaint, isLoading: complaintLoading } = useGetComplaintQuery();
	const { data: offer, isLoading: offerLoading } = useGetOffersQuery();
	const [modalData, setModalData] = useState();
	const [openTour, setOpenTour] = useState(false);

	// Refs for tour targets
	const titleRef = useRef();
	const exportRef = useRef();
	const cardRef = useRef();

	useEffect(() => {
		// Show tour only on first visit to reports page
		const hasSeenReportTour = localStorage.getItem('hasSeenReportTour');
		if (!hasSeenReportTour) {
			setOpenTour(true);
			localStorage.setItem('hasSeenReportTour', 'true');
		}
	}, []);

	const steps = [
		{
			title: t('tour.report_page.title'),
			description: t('tour.report_page.description'),
			target: () => titleRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.report_export.title'),
			description: t('tour.report_export.description'),
			target: () => exportRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.report_card.title'),
			description: t('tour.report_card.description'),
			target: () => cardRef.current,
			nextButtonProps: {
				style: { background: '#52c41a', borderColor: '#52c41a', color: 'white' },
				children: t('tour.finish'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
	];

	return (
		<>
			<Col xs={22} xl={22}>
				<div ref={titleRef}>
					<PageTitle title={t('report.title')}>
						<Button ref={exportRef} onClick={() => setOpenExportExcel(true)}>
							{t('export_excel')}
						</Button>
					</PageTitle>
				</div>
			</Col>
			<Col xs={22} xl={22}>
				<Row justify="start" gutter={[16, 16]}>
					{complaint?.complaint
						?.filter((item) => item.complaintStatus === 1)
						.map((item, index) => {
							return (
								<Col
									style={{ marginBottom: 20 }}
									key={item.id}
									xs={24}
									sm={12}
									md={8}
									lg={8}
									xl={{ span: 6, offset: index % 3 ? 1 : 0 }}
								>
									<div ref={index === 0 ? cardRef : null}>
										<ComplaintCard
											item={item}
											onClick={() => setModalData({ ...item, action: true })}
										/>
									</div>
								</Col>
							);
						})}

					{offer?.offer
						?.filter((item) => item.complaintStatus === 1)
						.map((item, index) => {
							return (
								<Col
									style={{ marginBottom: 20 }}
									key={item.id}
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={{ span: 6, offset: index % 3 ? 1 : 0 }}
								>
									<ComplaintCard
										item={item}
										onClick={() => setModalData({ ...item, action: true })}
									/>
								</Col>
							);
						})}
				</Row>
			</Col>
			<CustomModal
				data={modalData}
				isShow={modalData !== undefined}
				handleClose={() => setModalData(undefined)}
			/>
			<ExportExcelModal
				open={openExportExcel}
				onClose={() => setOpenExportExcel(false)}
				complaintStatus={1}
			/>
			<Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
		</>
	);
};

export default ReportPage;
